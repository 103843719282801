<template>
  <div>
    <el-dialog :visible.sync="centerDialogVisible" title="授权协议" width="1000px" center>
      <shouquanConent />
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="read" type="primary" :disabled="dialogCommand">我已阅读并同意协议 <span
            v-if="count>0">{{ '('+ count+')' }}</span></el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import shouquanConent from './shouquanConent.vue'

export default {
  name: 'agreementModal',
  components: { shouquanConent },
  data () {
    return {
      centerDialogVisible: false,
      interval: null,
      count: 10,
    }
  },
  methods: {
    openDialog () {
      clearInterval(this.interval)
      this.centerDialogVisible = true
      this.count = 10
      this.interval = setInterval(() => {
        this.count--
      }, 1000)
    },
    read () {
      this.$emit('read')
      this.centerDialogVisible = false
    }

  },
  watch: {
    count (new1) {
      if (new1 == 0) {
        clearInterval(this.interval)

      }
    },
  },
  computed: {
    dialogCommand () {
      if (this.count == 0) {

        return false
      }
      return true
    },
  },

}
</script>
<style scoped lang="stylus">
.dialog-footer {
  text-align: center;
  .el-button {
    width: 170px;
  }
}
.login-wrapper {
  width: 1100px;
  height: 520px;
  margin: auto;
  position relative
  .tab_box {
    padding: 10px 50px 30px;
    display: flex;
    flex-direction: column
    align-items: flex-start;
    box-sizing border-box;
    position: absolute;
    right: 0;
    width: 420px;
    height: 520px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 12px 12px;
    overflow hidden
    #tab {
      margin-top: 12px;
      width: 100%;
      .codeBtn {
        position: absolute;
        right: 0px;
        top: 9px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #165DFF;
        cursor pointer
      }
      .codeBtnTime {
        position: absolute;
        right: 0px;
        top: 9px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #A4A6A9;
      }
      .iconfont {
        position: absolute;
        left: 8px;
        top: 2px;
        font-size: 15px;
        z-index: 2;
        color: #1D2129;
      }
      .icon-icon_xianshi, .icon-icon_yincang {
        font-size: 15px;
        color: #1D2129;
        position: absolute;
        left: 297px;
        top: 2px;
      }
      .icon-icon_xianshi, .icon-icon_yincang:hover {
        cursor pointer
      }
    }
    .goRegister {
      position: absolute;
      width: 120px;
      height: 120px;
      background: #D3E0FD;
      border-radius: 50%;
      top: -60px;
      right: -60px;
      cursor pointer
      span {
        position: absolute;
        bottom: 25px;
        left: 18px
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #165DFF;
        line-height: 22px;
      }
    }
  }
}
.zcuser1 {
  margin-top: 23px !important;
}
.btnsendcode {
  position: absolute;
  right: 0;
  top: -16px;
  background: #fff;
  border-radius: 4px;
  color: #2F54EB;
  width: 102px;
  padding: 12px 0px;
  border: 1px solid #2F54EB;
  &:hover, &:focus, &:active {
    background: #fff;
    border-color: #fff;
  }
  &.active {
    // background: #D1D1D1;
    // color: #E6E6E6;
    // border-color: #D1D1D1;
  }
}
>>> .usernameinput {
  .el-input__inner {
    height: 40px;
    border-radius: 4px;
    text-indent: 10px;
  }
}
>>> .rememberpw {
  div {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #1D2129;
    span {
      color: #165DFF !important;
    }
  }
  .el-checkbox__inner {
    border-color: #A4A6A9
    width: 16px;
    height: 16px;
    border-radius: 2px 2px 2px 2px;
    &::after {
    }
  }
  .el-checkbox__inner:hover {
    border-color: #165DFF
  }
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2F54EB;
    border-color: #2F54EB;
  }
  .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
  .el-checkbox__inner::after {
    left: 5px;
  }
}
.forget {
  text-align: left;
  margin-top: -11px
}
.btn-send-sms {
  margin-top: 16px;
}
>>> .el-input__inner {
  padding-left: 30px;
  color: #1D2129;
}
>>> .el-tabs__nav-wrap::after {
  content: none;
}
>>> .el-tabs {
  width: 100%;
  margin: 0 auto;
}
>>> .el-tabs__nav-wrap {
  width: 100%;
  margin: 0 auto;
}
.loginitem {
  margin-top: 16px;
}
.loginbtn1 {
  height: 44px;
  background: #2663F6;
  width: 100%;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}
.loginbtn1:hover {
  background: #165DFF;
  color: #fff
}
.containernew {
  width: 100%;
  margin: 0 auto;
  >>> .a-form-item {
    margin-bottom: 24px;
  }
  >>> .a-form-item:nth-child(2) {
    margin-bottom: 20px;
  }
  .baseloginicon {
    position: absolute;
    left: 16px;
    color: #999999;
    width: 16px;
    height: 16px;
  }
}
//
.el-form-item {
  margin-bottom: 24px;
}
>>> .ant-tabs-nav {
  margin-bottom: 26px;
}
>>> .el-input__wrapper {
  padding: 3px 4px;
  box-shadow: 0 0 0 1px #fff inset;
  border-radius: 0
  color: #F54242;
  border-bottom: 1px solid #dedede;
}
>>> .el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 0 0 0px #fff inset;
  border-bottom: 1px solid #F54242;
}
>>> .el-form-item__error {
  color: #F54242;
  font-size: 12px;
  line-height: 1;
  padding-top: 3px;
  position: absolute;
  top: 100%;
  left: 10px;
}
>>> .el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #fff inset;
  border-bottom: 1px solid #165DFF !important;
}
>>> .el-form-item.is-error .el-input__wrapper:hover {
  box-shadow: 0 0 0 0px var(--el-color-danger) inset;
}
>>> .el-input__wrapper:hover {
  box-shadow: 0 0 0 0px #fff inset;
  border-bottom: 1px solid #A4A6A9;
}
>>> .el-form-item.is-error .el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #fff inset !important;
}
>>> .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #fff;
}
>>> .ant-tabs {
  font-weight: bold
}
>>> .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #165DFF;
}
>>> .ant-tabs-tab {
  font-size: 16px;
}
.placeholder {
  position absolute
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #A4A6A9;
  line-height: 12px;
  top: 40px;
  left: 10px;
  height: 22px;
  background: white;
  z-index: 12;
}
>>> .el-checkbox {
  //height:0;
  //margin-top:18px;
}
>>> .el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 0 1px 24px 24px;
}
>>> .el-dialog__header {
  padding-bottom: 20px;
  padding-top: 24px;
  .el-dialog__title {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
  }
}
>>> .el-overlay-dialog {
  //top: -40px;
  border-radius: 8px 8px 8px 8px;
}
>>> .el-dialog--center {
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.fixed-content::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 6px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
  position: relative;
  right: 4px;
}
.fixed-content::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: #606368;
}
.fixed-content::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  //-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: #fff;
}
>>> .ant-btn-default {
  border: none;
  font-size: 16px;
}
>>> .el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #606368;
  top: 6px;
}
</style>
