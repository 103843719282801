<template>
  <div>
    <van-popup v-model="vanPopupShareShow" closeable round position="bottom"
               :style="{ height: 'calc(100vh - 80px)' }">
      <div class="shareDiv">
        <div class="shareTitle">授权协议</div>
        <shouquanConent />
        <el-button @click="read" type="primary"
                   style="width:100%;margin-top: 0.10rem;height:44px;border:0;"
                   :disabled="dialogCommand">我已阅读并同意协议 <span v-if="count > 0">{{ '(' +
                        count + ')' }}</span></el-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import shouquanConent from './shouquanConent.vue'

export default {
  components: { shouquanConent },
  data () {
    return {
      vanPopupShareShow: false,
      interval: null,
      count: 10,
    }
  },
  computed: {
    dialogCommand () {
      if (this.count == 0) {
        return false
      }
      return true
    }
  },
  watch: {
    count (new1) {
      if (new1 == 0) {
        clearInterval(this.interval)

      }
    },
  },
  methods: {
    read () {
      this.$emit('read')
      this.vanPopupShareShow = false
    },
    openVanPopupShareShow () {
      clearInterval(this.interval)

      this.vanPopupShareShow = true
      this.count = 10
      this.interval = setInterval(() => {

        this.count--
      }, 1000)
    }
  }
}
</script>
<style lang="stylus" scoped>
.shareDiv {
  >>> .el-button {
    padding: 8px 12px;
    margin-left: 0px;
  }
  >>> .el-button.el-button--primary:not(.is-disabled) {
    background-color: #165DFF;
  }
  >>> .el-button.el-button--primary.is-disabled {
    background-color: #DFDFE0;
    border: 0;
  }
  .contentBox {
    height: 65vh;
    overflow: auto;
    .updateTime {
      text-align: center;
      font-size: 14px;
      color: #606368;
      margin-bottom: 16px;
    }
  }
  padding: 0.28rem 0.28rem 0.4rem 0.28rem;
  .content_title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 0.32rem;
    color: #1D2129;
    margin-bottom: 0.24rem;
  }
  .content {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 0.28rem;
    color: #1D2129;
    //margin-bottom :0.48rem;
  }
  .shareTitle {
    box-sizing: border-box;
    padding: 14px;
    height: 54px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
  }
}
</style>
